import React from 'react';
import {Row, Col, Card} from 'reactstrap';
import TaskFormVertical from "../form/taskFormVertical";
import CardBody from "reactstrap/es/CardBody";
import FontGeorgeIcon from "../visuals/fontGeorgeIcon";
import {
    PRODUCT_CODE,
    VF_ACTIVE,
    VF_BALANCE,
    VF_BE_NAME,
    VF_CLOSE_DATE,
    VF_NUMBER
} from "../../core/consts";
import DateTime from "../form/datetime";

const Product = ({entity, index, product, compressed, className, onClick, setHoverState, onRemove}) => {
    const getClasses = () => {
        let classes = [];
        classes.push(className ? className : '');
        classes.push(compressed ? 'compressed' : null);
        classes.push(onClick ? 'selectable' : '');
        classes.push('entity');
        classes.push('product');
        return classes.join(" ");
    };

    const isActive = () => {
        return product && (!product.closeDate || product.closeDate > new Date());
    };

    const getNumber = () => {
        if (product.cardNumber) {
            return formatCardNumber(product.cardNumber);
        } else if (product.accountNumber) {
            return product.accountPrefix + "-" + product.accountNumber + "/" + product.bankCode;
        } else if (product.evidenceNumber) {
            return product.evidenceNumber;
        } else {
            return <small className="text-muted">Není k dispozici</small>
        }
    };

    const formatCardNumber = (value) => {
        var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,16}/g);
        var match = (matches && matches[0]) || '';
        var parts = [];

        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4))
        }

        if (parts.length) {
            return parts.join(' ')
        } else {
            return value
        }
    };

    const isFieldValid = (val) => {
        return PRODUCT_CODE[product.financialProductId]?.validFields.some(vf => vf === val)
    };

    const postProcessProductName = (text) => {
        return text ? text.replace(/\+/g, " ") : "";
    };

    const handleOnClick = () => {
        if (entity) {
            onClick(entity)
        } else if (product) {
            onClick(product)
        }
    };

    const setHoverStateLocal = (index) => {
        if(setHoverState){
            setHoverState(index);
        }
    }

    return (
        <Card onClick={onClick ? () => handleOnClick() : null} onMouseEnter={() => setHoverStateLocal(index)} onMouseLeave={() => setHoverStateLocal("")} className={getClasses()}>
            <CardBody className="py-3">
                {compressed && product ?
                    <Row>
                        <Col md="auto" className="my-auto pl-0">
                            <TaskFormVertical label={product.backendProductName}
                                              labelClassName="mb-3 text-break text-uppercase font-weight-bold font-size-12 name d-block">
                                <span
                                    className="text-break text-lowercase font-weight-bolder font-size-11 name d-block"> {getNumber()}</span>
                            </TaskFormVertical>
                        </Col>
                    </Row>
                    : null}

                {compressed && entity ?
                    <Row>
                        <Col md="auto" className="my-auto pl-0">
                            <TaskFormVertical label={entity.backendProductName}
                                              labelClassName="mb-1 text-break text-uppercase font-weight-bold font-size-12 name d-block">
                                <span
                                    className="text-break text-lowercase font-weight-bolder font-size-12 name d-block"> {entity.label}</span>
                            </TaskFormVertical>
                        </Col>
                    </Row>
                    : null}

                {!compressed && product ?
                    <>
                        <Row className="text-left">
                            <Col className="pl-0 text-left">
                                {isFieldValid(VF_BE_NAME) ?
                                    <TaskFormVertical id="product">
                                        <span className="text-break text-uppercase font-weight-bold name d-block">
                                         {postProcessProductName(product.backendProductName)}
                                        </span>
                                    </TaskFormVertical>
                                    : null}
                            </Col>
                            <Col md="auto" className="py-1 px-2">
                                {onRemove ?
                                <FontGeorgeIcon title="Odstranit" icon="error-xl" className="selectable"
                                                onClick={(e) => onRemove(e)} width={30} height={30}/>
                                                :null}
                            </Col>
                        </Row>
                        <Row className="text-left">
                            <Col md={12} className="pl-0">
                                {isFieldValid(VF_NUMBER) ?
                                    <TaskFormVertical id="number" icon="file-xl" className="font-weight-bolder">
                                        <span>{getNumber()}</span>
                                    </TaskFormVertical>
                                    : null}
                            </Col>
                            <Col md={6} className="pl-0">
                                {isFieldValid(VF_CLOSE_DATE) ?
                                    <TaskFormVertical id="number" icon="calendar-xl" className="font-weight-bolder"
                                                      title="Platnost do">
                                        <DateTime millis={product.closeDate} format={"dd.MM.yyyy"}/>
                                    </TaskFormVertical>
                                    : null}

                                {isFieldValid(VF_BALANCE) ?
                                    <TaskFormVertical id="remainder" icon="pfm-fees-xl" className="font-weight-bolder">
                                        <span>{product.balance} {product.accountCurrency}</span>
                                    </TaskFormVertical>
                                    : null}
                            </Col>
                            <Col md={6} className="pl-0">
                                {isFieldValid(VF_ACTIVE) ?
                                    <TaskFormVertical id="state" icon="info-xl" className="font-weight-bolder">
                                        {isActive() ?
                                            <span className="active">Aktivní</span>
                                            :
                                            <span className="inactive">Neaktivní</span>
                                        }
                                    </TaskFormVertical>
                                    : null}
                            </Col>
                        </Row>
                    </>
                    : null}
            </CardBody>
        </Card>
    )
};

export default Product;